/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/require-default-props */
import { iStore } from 'domain/interfaces/models';
import { iEventItem } from 'domain/interfaces/models/Event';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxDeleteEvent } from 'main/factories/usecases/event/DeleteEventFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import {
  EntriesIcon,
  IconEllipis,
  IconLive,
  IconMoreOptions,
  IconPersonEvent,
  IconShare,
} from 'presentation/base/icons';
import Button from 'presentation/components/UI/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { formattedDate } from 'utils/formattedDate';
import { closeModal } from 'utils/modalFunctions';
import { makeReduxGetAllCategory } from 'main/factories/usecases/category/GetAllCategoryFactory';
import { makeReduxGetAllEvent } from 'main/factories/usecases/event/GetAllEventFactory';
import { TooltipComponent } from '../TooltipComponent';
import { SkeletonEventSmallPreview } from './skeleton';
import {
  AuthorizedButton,
  Buttons,
  Container,
  CounterPerson,
  DateEvent,
  Description,
  DescriptionCards,
  Entity,
  Footer,
  Header,
  Image,
  Info,
  Live,
  SubDescription,
  Tag,
  Tags,
  Title,
} from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ownProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  handleHover?: (value: boolean) => void;
  event?: iEventItem;
  skeleton?: boolean;
  categoryId?: number;
}

const EventSmallPreview: React.FC<ownProps> = ({
  image,
  className,
  handleHover,
  event,
  skeleton = true,
  categoryId = 1,
}) => {
  const [hover, setHover] = useState(false);

  const category = useSelector((store: iStore) => store.category);
  const wrtc = useSelector((store: iStore) => store.wrtc);
  const { user } = useSelector((store: iStore) => store.auth);

  const [open, setOpen] = useState(false);
  const [openOption, setOpenOption] = useState(false);

  const widthScreen = window.innerWidth;
  const history = useHistory();

  useEffect(() => {
    console.log(widthScreen);
  }, [widthScreen]);

  const handleEdit = useCallback(() => {
    if (event?.id) history.push(`/edit-event/${event.id}`);
  }, [event?.id, history]);

  const pathName = `/rooms/${event?.id}`;

  const handleLocalHover = useCallback(
    (value: boolean) => {
      setHover(value);
      if (handleHover) handleHover(value);
    },
    [handleHover],
  );

  const handleClick = useCallback(() => {
    if (!hover) return;

    if (!user?.email)
      return makeReduxActiveMessage().active({
        active: MessageOptions.loginModal,
        actionOk: () => closeModal(),
        actionCancel: () => closeModal(),
      });

    if (wrtc.disconnect) wrtc.disconnect();
    window.location.pathname = `/rooms/${event?.id}`;
  }, [event?.id, hover, user?.email, wrtc]);

  const handleClick2 = () => {
    setOpen(!open);
  };

  const handleClick3 = () => {
    setOpenOption(!openOption);
  };

  const handleDelete = useCallback(() => {
    if (event?.id) {
      makeReduxDeleteEvent().delete({
        eventId: event.id,
      });
      makeReduxGetAllEvent().getAll({
        limit: 9999,
      });
    }
  }, [event]);

  return skeleton ? (
    <SkeletonEventSmallPreview />
  ) : (
    <Container
      key={event?.id}
      onMouseEnter={() => handleLocalHover(true)}
      onMouseLeave={() => handleLocalHover(false)}
      className={className}
      onClick={handleClick}
    >
      <Image imageUrl={image} isHovering={hover}>
        {/* {hover && (
          <Buttons isHovering={hover}>
            <Button
              size="small"
              title="Entrar"
              icon={EntriesIcon}
              colorstyle="white1"
              onClick={handleClick}
            />
            <TooltipComponent
              open={open}
              placement="bottom"
              pathName={pathName}
              tooltipType="share"
            >
              <Button
                colorstyle="black5"
                size="very small"
                icon={IconShare}
                onClick={() => {
                  handleClick2();
                }}
              />
            </TooltipComponent>
            <TooltipComponent
              title="teste"
              placement="bottom"
              tooltipType="options"
              open={openOption}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            >
              <AuthorizedButton
                colorstyle="black5"
                size="very small"
                icon={IconMoreOptions}
                onClick={() => {
                  handleClick3();
                }}
              />
            </TooltipComponent>
          </Buttons>
        )} */}
        <div className="shade" />
      </Image>

      <Footer isHovering={hover}>
        <Title>
          <div className="text">{event?.name}</div>
        </Title>
        <div>
          <div className="sub-content">
            {event?.schedule
              ? formattedDate(new Date(event?.schedule), event?.duration)
              : 'Jan 1,2022 - 00h00'}
          </div>
        </div>
        {/* <DescriptionCards>
            {event?.descr
              ? `${event.category?.name ?? 'Categoria'} ${
                  event?.subCategories?.[0]?.name ??
                  event?.subCategories?.[0]?.subCategory
                    ? `- ${
                        event?.subCategories?.[0]?.name ??
                        event?.subCategories?.[0]?.subCategory
                      }`
                    : ''
                }`
              : 'Lorem Ipsum'}
          </DescriptionCards> */}
        {/* <SubDescription>
            <CounterPerson>0 Netfans</CounterPerson>
            <IconEllipis width={6} height={6} />
            <DateEvent>
              {event?.schedule
                ? formattedDate(new Date(event?.schedule))
                : 'Jan 1,2022 - 00h00'}
            </DateEvent>
          </SubDescription> */}
        {/* <Description>{event?.descr}</Description>
          <Tags>
            {event?.keywords?.map(item => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Tags> */}

        {/* {!hover && (
          <>
            <Entity>
              <DescriptionCards>
                {event?.descr
                  ? `${event.category.name ?? 'Categoria'} ${
                      event?.subCategories?.[0]?.name ??
                      event?.subCategories?.[0]?.subCategory
                        ? `- ${
                            event?.subCategories?.[0]?.name ??
                            event?.subCategories?.[0]?.subCategory
                          }`
                        : ''
                    }`
                  : 'Lorem Ipsum'}
              </DescriptionCards>
            </Entity>
            <SubDescription>
              <CounterPerson>
                <IconPersonEvent width={16} height={16} />0 Netfans
              </CounterPerson>
              <IconEllipis width={6} height={6} />
              <DateEvent>
                {event?.schedule
                  ? formattedDate(new Date(event?.schedule))
                  : 'Jan 1,2022 - 00h00'}
              </DateEvent>
            </SubDescription>
          </>
        )} */}
      </Footer>
    </Container>
  );
};

export default EventSmallPreview;
